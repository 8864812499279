Array.prototype.contains = function(what) {
  return this.filter(function(element) {
    return ("" + element).toLowerCase().indexOf(("" + what).toLowerCase()) !== -1;
  });
};

Date.prototype.addDays = function(days) {
  var d = new Date(this.valueOf());
  d.setDate(d.getDate() + days);
  return d;
};

Date.prototype.addMinutes = function(minutes) {
  var d = new Date(this.valueOf());
  d.setMinutes(d.getMinutes() + minutes);
  return d;
};
